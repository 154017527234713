import { type QueryParams, RequestMaker } from '../../request-maker';

export default class RecommendationsRequestMaker extends RequestMaker {
	makeRequest(makeRequestParams: QueryParams): Request {
		const { contextResolver, first, rerank } = makeRequestParams;
		return new Request(this.endpoint, {
			method: 'POST',
			credentials: 'include',
			mode: 'cors',
			headers: this.getHeaders(),
			body: JSON.stringify({
				query: recommendationsQuery,
				variables: {
					first,
					context: this.getContext(contextResolver),
					rerank,
				},
			}),
		});
	}
}

const recommendationsQuery = `
query GrowthRecQuery($context: GrowthRecContext!, $first: Int!, $rerank: [GrowthRecRerankCandidate!]) {
  growthRecommendations {
    recommendations(context: $context, first: $first, rerank: $rerank) {
      ... on GrowthRecRecommendations {
        __typename
        data {
          __typename
          entityId
          reasons
        }
      }
      ... on QueryError {
        __typename
        identifier
        message
        extensions {
          __typename
          statusCode
          errorType
        }
      }
    }
  }
}
`;

import { type ProvisioningApiExtensionsOptions } from './types';
import { createProductExtensions } from './utils';

export type { ProvisioningApiExtensionsOptions } from './types';

export const provisioningApiExtensionsWhitelist = ['provisioningApiParams'];

export const createProvisioningApiExtensions = (options: ProvisioningApiExtensionsOptions) =>
	createProductExtensions<{
		provisioningApiParams: ProvisioningApiExtensionsOptions;
	}>(provisioningApiExtensionsWhitelist)({
		provisioningApiParams: options,
	});

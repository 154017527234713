import { type AppRecContext, type ContextResolver } from '../types';

type EmptyValue = null | undefined | '' | [] | Record<string, never>;

/**
 * Check if a value is empty.
 */
export function isNotEmpty<T>(value: T | EmptyValue): value is T {
	if (value === null || value === undefined || value === '') {
		return false;
	}

	if (Array.isArray(value) && value.length === 0) {
		return false;
	}

	if (typeof value === 'object' && Object.keys(value).length === 0) {
		return false;
	}

	return true;
}

export function getContext(
	contextResolver: ContextResolver,
	checkUseCase: boolean = true,
): AppRecContext {
	const context = typeof contextResolver === 'function' ? contextResolver() : contextResolver;

	if (
		checkUseCase &&
		(typeof context?.useCase !== 'string' || context?.useCase.trim().length === 0)
	) {
		throw new Error('useCase is required');
	}

	return context;
}

import React from 'react';

import Icon, { type IconProps } from '@atlaskit/icon';
import type { CustomGlyphProps } from '@atlaskit/icon/types';

const SparkleGlyph = (props: CustomGlyphProps) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
		<path
			id="starMain"
			fillRule="evenodd"
			d="m5.184 1.921-1.28 3.244L.664 6.443a.52.52 0 0 0 0 .966l3.242 1.279 1.279 3.242a.52.52 0 0 0 .967 0l1.278-3.242 3.242-1.28a.52.52 0 0 0 0-.966L7.43 5.165 6.15 1.92a.517.517 0 0 0-.69-.285.519.519 0 0 0-.276.285Z"
			clipRule="evenodd"
			fill="currentColor"
		/>
		<path
			id="starBottomRight"
			fillRule="evenodd"
			d="m10.246 9.237-.496 1.255-1.255.496a.37.37 0 0 0-.205.494c.04.09.113.162.205.198l1.255.495.496 1.256a.373.373 0 0 0 .692 0l.496-1.256 1.255-.495a.371.371 0 0 0 0-.692l-1.255-.496-.496-1.255a.369.369 0 0 0-.494-.205.373.373 0 0 0-.198.205Z"
			clipRule="evenodd"
			fill="currentColor"
		/>
		<path
			id="starTopRight"
			fillRule="evenodd"
			d="m10.715.568-.495 1.257-1.256.496a.373.373 0 0 0 0 .692l1.256.495.495 1.257a.372.372 0 0 0 .692 0l.497-1.257 1.255-.495a.371.371 0 0 0 0-.692l-1.255-.496L11.407.57a.367.367 0 0 0-.209-.21.375.375 0 0 0-.483.209Z"
			clipRule="evenodd"
			fill="currentColor"
		/>
	</svg>
);

// TO DO - https://hello.jira.atlassian.cloud/browse/TAKO-2372
// eslint-disable-next-line @atlaskit/design-system/no-custom-icons
export const SparkleIcon = (props: IconProps) => <Icon glyph={SparkleGlyph} {...props} />;

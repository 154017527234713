import React from 'react';

import AccountMenu, { type AccountMenuProps } from '@atlassian/account-menu';

import { type AccountLinks, type AccountLinksParams, useAccountLinks } from '../../hooks';

export type AccountProps = AccountLinksParams & Omit<AccountMenuProps, keyof AccountLinks>;

export const Account: React.ComponentType<AccountProps> = ({
	identityEnvironment,
	application,
	continueUrl,
	logOutContinueUrl,
	isLoggedOut,
	disableSwitchAccountLink,
	...props
}) => {
	const links = useAccountLinks({
		email: props.email,
		identityEnvironment,
		application,
		continueUrl,
		logOutContinueUrl,
		isLoggedOut,
		disableSwitchAccountLink,
	});

	return <AccountMenu {...props} {...links} />;
};

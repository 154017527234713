import { CacheConfig } from '../cache-config';
import { type CacheProviderConfig, type ResponseCacheProvider } from '../types';

import { ExpiryStorage } from './expiry-storage';

export default class WebCacheStorage implements ResponseCacheProvider {
	/**
	 * Whether the Cache API is supported
	 */
	private supported: boolean;

	/**
	 * Expiry storage
	 */
	private expiryStorage: ExpiryStorage;

	private cacheConfig: CacheConfig;

	/**
	 * Initialise the WebCacheStorage
	 * @param cacheName The name of the cache to use. See {@link https://developer.mozilla.org/en-US/docs/Web/API/CacheStorage/open}
	 * @param onError a callback when any error occurs
	 */
	constructor(cacheProviderConfig: CacheProviderConfig) {
		this.supported = typeof caches === 'object' && typeof caches.open === 'function';

		this.cacheConfig = new CacheConfig(cacheProviderConfig);

		this.expiryStorage = new ExpiryStorage(this.cacheConfig);
	}

	/**
	 * Get cached response
	 */
	async get(request: Request): Promise<Response | undefined> {
		if (!this.supported) {
			return undefined;
		}

		try {
			const cache = await caches.open(this.cacheConfig.cacheName);
			const expired = await this.isExpired(request);

			if (expired) {
				await this.delete(request);
				return undefined;
			}

			return cache.match(this.getCacheKey(request));
		} catch (e) {
			this.cacheConfig.onError(e as unknown as Error);
		}
	}

	/**
	 * Put response in cache
	 */
	async set(request: Request, response: Response): Promise<void> {
		if (!this.supported) {
			return;
		}

		try {
			const cache = await caches.open(this.cacheConfig.cacheName);
			const expiryDate = Date.now() + this.cacheConfig.ttl * 1000;

			await this.expiryStorage.setExpiry(this.getStoreKey(request), expiryDate);
			await cache.put(this.getCacheKey(request), response);
		} catch (e) {
			this.cacheConfig.onError(e as unknown as Error);
		}
	}

	/**
	 * Delete cached response
	 */
	async delete(request: Request): Promise<boolean> {
		if (!this.supported) {
			return true;
		}

		try {
			return caches.open(this.cacheConfig.cacheName).then(async (cache) => {
				await cache.delete(this.getCacheKey(request));
				await this.expiryStorage.deleteExpiry(this.getStoreKey(request));
				return true;
			});
		} catch (e) {
			this.cacheConfig.onError(e as unknown as Error);

			return false;
		}
	}

	/**
	 * Check if the cached response is expired
	 */
	private async isExpired(request: Request): Promise<boolean> {
		try {
			const key = this.getStoreKey(request);
			const expiry = await this.expiryStorage.getExpiry(key);

			return Date.now() > (expiry ?? 0);
		} catch (error) {
			this.cacheConfig.onError(error instanceof Error ? error : new Error('Error checking expiry'));
			return true;
		}
	}

	/**
	 * Compose the URL and the use case as the search params to cache the response
	 */
	private getCacheKey(request: Request): URL {
		const url = new URL(request.url);
		url.searchParams.set('useCase', this.cacheConfig.useCase);

		return url;
	}

	/**
	 * Get the key to store the response
	 * Interally, it hashes the request body to get a unique key
	 */
	private getStoreKey(request: Request): string {
		return this.getCacheKey(request).toString();
	}
}

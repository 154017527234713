import React from 'react';

import ReactDOM, { version } from 'react-dom';
import type { TaskScheduler } from 'relay-runtime';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default {
	cancel: () => {},
	schedule: (fn) => {
		// react 16.x
		if (
			// `unstable_batchedUpdates` is defined in all currently supported versions
			// but could be removed in the future.
			// This check is defensive and not currently necessary.
			typeof ReactDOM.unstable_batchedUpdates === 'function' &&
			// The version export was only introduced in `react-dom@16.13.0`
			// but we need to support `react-dom@^16.8.0`
			// so we need to handle when the version is `undefined`
			(typeof version === 'undefined' || version.startsWith('16'))
		) {
			ReactDOM.unstable_batchedUpdates(() => {
				fn();
			});
		}
		// react 18.x
		// @ts-ignore-error React.startTransition does not exist in react-16
		else if (typeof React.startTransition === 'function') {
			// @ts-ignore-error React.startTransition does not exist in react-16
			React.startTransition(() => {
				fn();
			});
		} else {
			fn();
		}

		return '';
	},
} as TaskScheduler;

/**
 * An Identity environment to integrate with. Either a `"dev"`, `"fedex"`, `"staging"` or
 * `"prod"` for real Identity environments, or an `EnvironmentConfig` object for
 * using a fake environment (e.g. for local dev).
 */
export type IdentityEnvironment = EnvironmentType | EnvironmentConfig;
export type EnvironmentType =
	| 'dev'
	| 'fedex'
	| 'staging'
	| 'staging-fedramp-moderate'
	| 'prod'
	| 'prod-fedramp-moderate';
export type EnvironmentConfig = {
	/**
	 * Base URL for Atlassian account, e.g. `https://id.atlassian.com`. Should be
	 * an absolute URL and must not have a trailing `/`.
	 */
	baseUrl: string;
};

const environmentConfigs: Record<EnvironmentType, EnvironmentConfig> = {
	dev: {
		baseUrl: 'https://id.dev.internal.atlassian.com',
	},
	fedex: {
		baseUrl: 'https://id.atlassian-fex.com',
	},
	staging: {
		baseUrl: 'https://id.stg.internal.atlassian.com',
	},
	'staging-fedramp-moderate': {
		baseUrl: 'https://id.stg.atlassian-us-gov-mod.com',
	},
	prod: {
		baseUrl: 'https://id.atlassian.com',
	},
	'prod-fedramp-moderate': {
		baseUrl: 'https://id.atlassian-us-gov-mod.com',
	},
};

export function resolveEnvironment(identityEnvironment: IdentityEnvironment): EnvironmentConfig {
	if (typeof identityEnvironment === 'string') {
		return environmentConfigs[identityEnvironment];
	} else {
		return identityEnvironment;
	}
}

import {
  JiraServicedeskQueueAriResourceOwner,
  JiraServicedeskQueueAriResourceType
} from "./chunk-TC6T4VFX.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira-servicedesk/queue/manifest.ts
var jiraServicedeskQueueAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraServicedeskQueueAriResourceOwner,
  resourceType: JiraServicedeskQueueAriResourceType,
  resourceIdSlug: "activation/{activationId}/{queueId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    queueId: /[0-9]+/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira-servicedesk/queue/index.ts
var JiraServicedeskQueueAri = class _JiraServicedeskQueueAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._queueId = opts.resourceIdSegmentValues.queueId;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get queueId() {
    return this._queueId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraServicedeskQueueAriStaticOpts.qualifier,
      platformQualifier: jiraServicedeskQueueAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraServicedeskQueueAriStaticOpts.resourceOwner,
      resourceType: jiraServicedeskQueueAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.queueId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        queueId: opts.queueId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraServicedeskQueueAriStaticOpts);
    return new _JiraServicedeskQueueAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraServicedeskQueueAriStaticOpts);
    return new _JiraServicedeskQueueAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      queueId: this.queueId
    };
  }
};

export {
  JiraServicedeskQueueAri
};

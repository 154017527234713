import {
  JiraFilterAriResourceOwner,
  JiraFilterAriResourceType
} from "./chunk-NQBZEVCS.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/filter/manifest.ts
var jiraFilterAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraFilterAriResourceOwner,
  resourceType: JiraFilterAriResourceType,
  resourceIdSlug: "activation/{activationId}/{filterId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    filterId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/filter/index.ts
var JiraFilterAri = class _JiraFilterAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._filterId = opts.resourceIdSegmentValues.filterId;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get filterId() {
    return this._filterId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraFilterAriStaticOpts.qualifier,
      platformQualifier: jiraFilterAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraFilterAriStaticOpts.resourceOwner,
      resourceType: jiraFilterAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.filterId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        filterId: opts.filterId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraFilterAriStaticOpts);
    return new _JiraFilterAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraFilterAriStaticOpts);
    return new _JiraFilterAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      filterId: this.filterId
    };
  }
};

export {
  JiraFilterAri
};

import {
	type AppRecRecommendationResult,
	GraphQlQueryResult,
	type QueryResult,
	QuerySuccessResultCreator,
} from '../../graphql-result';

export class RecommendationSuccessResultCreator extends QuerySuccessResultCreator<AppRecRecommendationResult> {
	constructor(
		protected response: Response,
		data: AppRecRecommendationResult,
	) {
		super(response, data);
	}

	makeQueryResult(): QueryResult<AppRecRecommendationResult> {
		return new GraphQlQueryResult(this.response.status, this.response.statusText, {
			data: this.data,
		});
	}
}

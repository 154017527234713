import {
  JiraPlanAriResourceOwner,
  JiraPlanAriResourceType
} from "./chunk-GGTGTA2K.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/plan/manifest.ts
var jiraPlanAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraPlanAriResourceOwner,
  resourceType: JiraPlanAriResourceType,
  resourceIdSlug: "activation/{activationId}/{planId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    planId: /[0-9]+/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/plan/index.ts
var JiraPlanAri = class _JiraPlanAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._planId = opts.resourceIdSegmentValues.planId;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get planId() {
    return this._planId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraPlanAriStaticOpts.qualifier,
      platformQualifier: jiraPlanAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraPlanAriStaticOpts.resourceOwner,
      resourceType: jiraPlanAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.planId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        planId: opts.planId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraPlanAriStaticOpts);
    return new _JiraPlanAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraPlanAriStaticOpts);
    return new _JiraPlanAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      planId: this.planId
    };
  }
};

export {
  JiraPlanAri
};

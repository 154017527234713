import { type AppRecRecommendationResult } from '../../../graphql-result';
import {
	type AppRecRecommendationsResponse,
	type QueryResponseBroker,
} from '../../../response-broker';
import { RecommendationSuccessResultCreator } from '../index';

export class RecommendationsRequestBroker
	implements QueryResponseBroker<AppRecRecommendationsResponse, AppRecRecommendationResult>
{
	extract(response: AppRecRecommendationsResponse | undefined): AppRecRecommendationResult {
		return response?.recommendations?.data ?? [];
	}

	getSuccessResultCreator(
		response: Response,
		result: AppRecRecommendationResult,
	): RecommendationSuccessResultCreator {
		return new RecommendationSuccessResultCreator(response, result);
	}
}

import { type CacheConfig } from '../../cache-config';

/**
 * Expiry storage that stores the expiry date of the cached response
 */
export default class ExpiryStorage {
	private expiryStoreName = 'expiries';

	/**
	 * Initialise the ExpiryStorage
	 */
	constructor(private cacheConfig: CacheConfig) {}

	/**
	 * Set the expiry date of the cached response
	 */
	async setExpiry(key: string, expireAt: number): Promise<void> {
		return new Promise((resolve, reject) => {
			const dbRequest = this.initDb();

			dbRequest.onsuccess = () => {
				const store = this.getObjectStore(dbRequest, 'readwrite');

				store.put(expireAt, key);
				resolve();
			};

			dbRequest.onerror = () => {
				if (dbRequest.error) {
					reject(dbRequest.error);
				}
			};
		});
	}

	/**
	 * Get the expiry date of the cached response
	 */
	async getExpiry(key: string): Promise<number | undefined> {
		return new Promise((resolve, reject) => {
			const dbRequest = this.initDb();

			dbRequest.onsuccess = () => {
				const store = this.getObjectStore(dbRequest, 'readonly');

				const storeRequest = store.get(key);

				storeRequest.onsuccess = () => {
					resolve(Number(storeRequest.result ?? 0));
				};
			};

			dbRequest.onerror = () => {
				if (dbRequest.error) {
					reject(dbRequest.error);
				}
			};
		});
	}

	async deleteExpiry(key: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const dbRequest = this.initDb();
			dbRequest.onsuccess = () => {
				const store = this.getObjectStore(dbRequest, 'readwrite');
				store.delete(key);
				resolve();
			};

			dbRequest.onerror = () => {
				if (dbRequest.error) {
					reject(dbRequest.error);
				}
			};
		});
	}

	/**
	 * Initialise the IndexedDB
	 */
	private initDb(): IDBOpenDBRequest {
		const dbRequest = indexedDB.open(`${this.cacheConfig.cacheName}-expiry`);

		dbRequest.onupgradeneeded = () => {
			const db = dbRequest.result;
			db.createObjectStore(this.expiryStoreName);
		};

		return dbRequest;
	}

	/**
	 * Get the object store from the IndexedDB
	 */
	private getObjectStore(dbRequest: IDBOpenDBRequest, mode: IDBTransactionMode = 'readwrite') {
		const db = dbRequest.result;
		return db.transaction(this.expiryStoreName, mode).objectStore(this.expiryStoreName);
	}
}

import { NoCache } from './no-cache';
import { type CacheProviderConfig, type ResponseCacheProvider } from './types';
import { WebCacheStorage } from './web-cache-storage';

export default class ResponseCacheProviderResolver {
	/**
	 * Returns the cache provider based on the given cache configuration
	 *
	 * @param cacheConfig The cache configuration to use
	 * @returns The cache provider
	 */
	static resolve(cacheConfig?: CacheProviderConfig): ResponseCacheProvider {
		if (cacheConfig) {
			return new WebCacheStorage(cacheConfig);
		}

		return new NoCache();
	}
}

import { type CacheProviderConfig } from '../types';

const DEFAULT_TTL: number = 60;

export default class CacheConfig {
	constructor(private cacheProviderConfig: CacheProviderConfig) {}

	get cacheName(): string {
		return this.cacheProviderConfig.cacheName;
	}

	get useCase(): string {
		return this.cacheProviderConfig.useCase;
	}

	get ttl(): number {
		return this.cacheProviderConfig.ttl ?? DEFAULT_TTL;
	}

	get onError(): NonNullable<CacheProviderConfig['onError']> {
		return this.cacheProviderConfig.onError ?? (() => {});
	}
}

import { type ResponseCacheProvider } from '../types';

export default class NoCache implements ResponseCacheProvider {
	async get(request: Request): Promise<Response | undefined> {
		return undefined;
	}

	async set(request: Request, response: Response): Promise<void> {
		return;
	}

	async delete(request: Request): Promise<boolean> {
		return true;
	}
}

import {
	type AppRecRecommendationResult,
	GeneralQueryErrorResultCreator,
	MissingQueryDataResultCreator,
	NonOkQueryResultCreator,
	type QueryResult,
} from '../../graphql-result';
import {
	type AppRecRecommendationsResponse,
	type QueryResponseBroker,
} from '../../response-broker';
import {
	type CacheProviderConfig,
	ResponseCacheProviderResolver,
} from '../../response-cache-provider';

interface RequesterFetchConfig {
	cacheConfig?: CacheProviderConfig;
}

/** The requester to make query operation to App Recommendations API via AGG
 *
 * Currently, App Recommendations Service has only a single query (and we only use a single query), which doesn't return any Query errors
 * @see {@link https://developer.atlassian.com/platform/graphql-gateway/standards/query-error/} for more about query errors
 * The query error checking is skipped for now, but it can be added in the future if needed
 */
export default class Requester<
	T extends AppRecRecommendationsResponse,
	R extends AppRecRecommendationResult,
> {
	constructor(private broker: QueryResponseBroker<T, R>) {}

	/**
	 * Fetches the response by the given request from the cache or the API
	 * the cache config is optional, if not provided, the response will not be cached
	 *
	 * @param request The request to fetch the response from
	 * @param cacheConfig The cache configuration to use
	 * @returns The query result
	 */
	fetch(request: Request, { cacheConfig }: RequesterFetchConfig = {}): Promise<QueryResult<R>> {
		return new Promise(async (resolve, reject) => {
			const response = await this.getResponse(request, cacheConfig);

			if (!response.ok) {
				return reject(new NonOkQueryResultCreator(response).makeQueryResult());
			}

			const json = await response.json();

			if (json.errors) {
				return reject(new GeneralQueryErrorResultCreator(json.errors).makeQueryResult());
			}

			const schemaResponse = json?.data?.growthRecommendations as T | undefined;

			const queryRes = this.broker.extract(schemaResponse);

			// Undefined is not expected as a valid response from GraphQL
			if (queryRes === undefined) {
				return reject(new MissingQueryDataResultCreator(response).makeQueryResult());
			}

			return resolve(this.broker.getSuccessResultCreator(response, queryRes).makeQueryResult());
		});
	}

	/**
	 * Fetches the response by tha given request from the cache or the API
	 * the cache config is optional, if not provided, the response will not be cached
	 *
	 * @param request The request to fetch the response from
	 * @param cacheConfig The cache configuration to use
	 * @returns The response from the request
	 */
	private async getResponse(
		request: Request,
		cacheConfig?: CacheProviderConfig,
	): Promise<Response> {
		const cacheProvider = ResponseCacheProviderResolver.resolve(cacheConfig);

		const cachedResponse = await cacheProvider.get(request);

		// If the response is cached, return it
		if (cachedResponse) {
			return cachedResponse;
		}

		// Clone the request before it being used to be able to use it multiple times
		const clonedRequest = request.clone();

		return fetch(request).then((response) => {
			// Cache response only if the response is ok
			if (response.ok) {
				cacheProvider.set(clonedRequest, response.clone());
			}

			return response;
		});
	}
}

import {
	type AppRecContext,
	type ContextResolver,
	type GrowthRecRerankCandidate,
} from '../../common/types';
import { getContext } from '../../common/utils/utils';

export type MutationParams = {
	productId: string;
	contextResolver: ContextResolver;
};

export type QueryParams = {
	contextResolver: ContextResolver;
	first?: number;
	rerank?: GrowthRecRerankCandidate[];
};

type MakeRequestParams = MutationParams | QueryParams;

export default abstract class RequestMaker {
	constructor(protected readonly endpoint: string) {}

	public abstract makeRequest(makeRequestParams: MakeRequestParams): Request;

	protected getHeaders(): Headers {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		return headers;
	}

	protected getContext(contextResolver: ContextResolver): AppRecContext {
		return getContext(contextResolver);
	}
}

import {
  JiraSoftwareBoardAriResourceOwner,
  JiraSoftwareBoardAriResourceType
} from "./chunk-YJAJS2GF.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira-software/board/manifest.ts
var jiraSoftwareBoardAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraSoftwareBoardAriResourceOwner,
  resourceType: JiraSoftwareBoardAriResourceType,
  resourceIdSlug: "{boardId}",
  resourceIdSegmentFormats: {
    boardId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira-software/board/index.ts
var JiraSoftwareBoardAri = class _JiraSoftwareBoardAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._boardId = opts.resourceIdSegmentValues.boardId;
  }
  get siteId() {
    return this._siteId;
  }
  get boardId() {
    return this._boardId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraSoftwareBoardAriStaticOpts.qualifier,
      platformQualifier: jiraSoftwareBoardAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraSoftwareBoardAriStaticOpts.resourceOwner,
      resourceType: jiraSoftwareBoardAriStaticOpts.resourceType,
      resourceId: `${opts.boardId}`,
      resourceIdSegmentValues: {
        boardId: opts.boardId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraSoftwareBoardAriStaticOpts);
    return new _JiraSoftwareBoardAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraSoftwareBoardAriStaticOpts);
    return new _JiraSoftwareBoardAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      boardId: this.boardId
    };
  }
};

export {
  JiraSoftwareBoardAri
};

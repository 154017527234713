import type { AccountMenuProps } from '@atlassian/account-menu';

import { type IdentityEnvironment, resolveEnvironment } from '../../utils';

export type AccountLinksParams = Pick<AccountMenuProps, 'email'> & {
	/**
	 * The Identity environment to integrate with. This should be `"prod"` for
	 * applications in production and typically `"staging"` for other environments.
	 * For FedRAMP environments, use `"prod-fedramp-moderate"` or `"staging-fedramp-moderate"`.
	 */
	identityEnvironment: IdentityEnvironment;
	/**
	 * An application key for Atlassian account cobranding, e.g. `jira`, `trello`.
	 */
	application: string;
	/**
	 * The continue URL for the log in, log out and switch account links. The user
	 * will be redirected to this URL after logging in, logging out or switching
	 * account. It should typically be the application's home page, e.g.
	 * `https://bitbucket.org/`.
	 */
	continueUrl: string;
	/**
	 * An alternative continue URL to use for the log out link instead of
	 * `continueUrl`.
	 */
	logOutContinueUrl?: string;
	/**
	 * `true` to display the logged-out state, which is a link to log in. `name`,
	 * `picture` and `email` won't be used and can be `undefined` if this is
	 * `true`.
	 */
	isLoggedOut?: boolean;
	/**
	 * `true` to disable the switch account link. Should only be used in tenanted
	 * products (e.g. product on `atlassian.net` such as Jira, Confluence,
	 * Compass, etc).
	 */
	disableSwitchAccountLink?: boolean;
};

export type AccountLinks = Pick<
	AccountMenuProps,
	'manageProfileLink' | 'switchAccountLink' | 'logOutLink' | 'logInLink'
>;

export function useAccountLinks({
	email,
	identityEnvironment,
	application,
	continueUrl,
	logOutContinueUrl,
	isLoggedOut,
	disableSwitchAccountLink,
}: AccountLinksParams): AccountLinks {
	const { baseUrl } = resolveEnvironment(identityEnvironment);

	const applicationParam = `application=${encodeURIComponent(application)}`;
	const continueParam = `continue=${encodeURIComponent(continueUrl)}`;
	const logOutContinueParam = logOutContinueUrl
		? `continue=${encodeURIComponent(logOutContinueUrl)}`
		: continueParam;

	const manageProfileUrl = `${baseUrl}/manage-profile`;
	const manageProfileLink = email
		? `${baseUrl}/login?${applicationParam}&continue=${encodeURIComponent(
				manageProfileUrl,
			)}&prompt=none&login_hint=${encodeURIComponent(email)}`
		: manageProfileUrl;

	return {
		manageProfileLink,
		switchAccountLink: disableSwitchAccountLink
			? undefined
			: `${baseUrl}/login?${applicationParam}&${continueParam}&prompt=select_account`,
		logOutLink:
			`${baseUrl}/logout?${applicationParam}&${logOutContinueParam}` +
			(disableSwitchAccountLink ? '' : '&prompt=none'),
		logInLink: isLoggedOut ? `${baseUrl}/login?${applicationParam}&${continueParam}` : undefined,
	};
}

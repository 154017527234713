import type { GeneralError } from '../index';
import { type AppRecRecommendationResult, type QueryResult } from '../types';

/**
 * The query result holds the necessary data that extract from the fetch response.
 */
export class GraphQlQueryResult<T extends AppRecRecommendationResult> implements QueryResult<T> {
	constructor(
		public code: number,
		public reason: string,
		public response?: {
			data?: T;
			errors?: string[];
		},
	) {}
}

abstract class QueryResultCreator<T extends AppRecRecommendationResult | undefined> {
	abstract makeQueryResult(): QueryResult<T>;
}

/**
 * This class is responsible for creating a query result for a non-OK fetch response.
 */
export class NonOkQueryResultCreator extends QueryResultCreator<AppRecRecommendationResult> {
	constructor(private fetchResponse: Response) {
		super();
	}

	makeQueryResult(): QueryResult<AppRecRecommendationResult> {
		return new GraphQlQueryResult(this.fetchResponse.status, this.fetchResponse.statusText);
	}
}

/**
 * This class is responsible for creating a query result for a fetch response that contains general errors.
 */
export class GeneralQueryErrorResultCreator extends QueryResultCreator<AppRecRecommendationResult> {
	constructor(private errors: GeneralError[]) {
		super();
	}

	makeQueryResult(): QueryResult<AppRecRecommendationResult> {
		const [{ extensions }] = this.errors;
		const [{ statusCode, errorType }] = Array.isArray(extensions) ? extensions : [extensions];

		return new GraphQlQueryResult(statusCode, errorType, {
			errors: this.errors.map((error) => error.message),
		});
	}
}

/**
 * This class is responsible for creating a query result for a fetch response that missing the key attributes.
 */
export class MissingQueryDataResultCreator extends QueryResultCreator<AppRecRecommendationResult> {
	constructor(private fetchResponse: Response) {
		super();
	}

	makeQueryResult(): QueryResult<AppRecRecommendationResult> {
		return new GraphQlQueryResult(this.fetchResponse.status, this.fetchResponse.statusText, {
			errors: ['Received data has missing attributes'],
		});
	}
}

export abstract class QuerySuccessResultCreator<
	T extends AppRecRecommendationResult,
> extends QueryResultCreator<T> {
	constructor(
		protected response: Response,
		protected data: T,
	) {
		super();
	}
}

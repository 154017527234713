import React from 'react';

import { CACHE_NAME } from '../../common/constants';
import { type ContextResolver, type GrowthRecRerankCandidate } from '../../common/types';
import { getContext } from '../../common/utils/utils';
import { type AppRecRecommendationResult, type QueryResult } from '../graphql-result';
import { type AppRecRecommendationsResponse } from '../response-broker';
import { type CacheProviderConfig } from '../response-cache-provider';

import { RecommendationsRequestMaker } from './recommendations-request-maker';
import { RecommendationsRequestBroker } from './recommendations-result/response-broker';
import { Requester } from './requester';

export interface GetRecommendationsConfig {
	cacheConfig?: Omit<CacheProviderConfig, 'cacheName' | 'useCase'>;
}

export interface GetRecommendationArgs {
	first?: number;
	rerank?: GrowthRecRerankCandidate[];
	config?: GetRecommendationsConfig;
}

export interface UseRecommendations {
	getRecommendations: (
		args: GetRecommendationArgs | number,
		deprecatedRerank?: GrowthRecRerankCandidate[],
	) => Promise<QueryResult<AppRecRecommendationResult>>;
}

export interface UseRecommendationArgs {
	endpoint?: string;
	contextResolver: ContextResolver;
}

const useRecommendations = ({
	endpoint = '/gateway/api/graphql',
	contextResolver,
}: UseRecommendationArgs): UseRecommendations => {
	/**
	 * Fetch recommendations for a given context
	 */
	const getRecommendations: UseRecommendations['getRecommendations'] = React.useCallback(
		(args, deprecatedRerank = []): Promise<QueryResult<AppRecRecommendationResult>> => {
			// Deconstruct the args object with deprecated arguments to make it backwards compatible
			const {
				first,
				rerank,
				config: { cacheConfig } = {
					cacheConfig: undefined,
				},
			} = typeof args === 'number' ? { first: args, rerank: deprecatedRerank } : args;
			const recommendationRequestMaker = new RecommendationsRequestMaker(endpoint);

			const requester = new Requester<AppRecRecommendationsResponse, AppRecRecommendationResult>(
				new RecommendationsRequestBroker(),
			);

			const context = getContext(contextResolver);

			return requester.fetch(
				recommendationRequestMaker.makeRequest({
					contextResolver,
					first,
					rerank,
				}),
				{
					cacheConfig: cacheConfig
						? { ...cacheConfig, cacheName: CACHE_NAME, useCase: context.useCase }
						: undefined,
				},
			);
		},
		[contextResolver, endpoint],
	);

	return { getRecommendations };
};

export default useRecommendations;

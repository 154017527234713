import { type MutableRefObject, useLayoutEffect, useRef } from 'react';

/**
 * This class tracks nudge target HTML DOM elements by ID.
 * It also tracks subscribers to updates of these elements.
 *  See some of the history here https://hello.atlassian.net/wiki/spaces/~620663553/pages/2896059847/Shipit+57+-+Out+of+Tree+Nudges
 */
class NudgeController {
	nudgeTargetsMap: Map<string, MutableRefObject<HTMLElement | null>> = new Map();

	subscribers: Map<string, (() => void)[]> = new Map();

	/**
	 * Singleton instance of this class.
	 * Alternatively multiple instances would be created and passed through context.
	 */
	static instance: NudgeController = new NudgeController();

	/**
	 * Register the HTML DOM node for an ID
	 */
	registerNudgeTarget(id: string, ref: MutableRefObject<HTMLElement | null>): () => void {
		this.nudgeTargetsMap.set(id, ref);
		this.notifySubscribers(id);

		// Return a cleanup function that will remove this ID from the map
		return () => {
			this.nudgeTargetsMap.delete(id);
		};
	}

	/**
	 * Subscribe to updates to a certain DOM node
	 */
	subscribe(id: string, callback: () => void): () => void {
		const subscribers = this.subscribers.get(id) ?? [];
		subscribers.push(callback);
		this.subscribers.set(id, subscribers);

		// Return a cleanup function that will remove this callback from the list of subscribers
		return () => {
			const subscribersOnCleanup = this.subscribers.get(id) ?? [];
			this.subscribers.set(
				id,
				subscribersOnCleanup.filter((cb) => cb !== callback),
			);
		};
	}

	/**
	 * Notify subscribers that the HTML DOM node for an ID has been updated.
	 */
	private notifySubscribers(id: string) {
		const subscribers = this.subscribers.get(id) ?? [];
		subscribers.forEach((subscriber) => {
			subscriber();
		});
	}
}

/**
 * Return the singleton controller
 */
export const useNudgeController = (): NudgeController => NudgeController.instance;

export interface NudgeTarget<T extends HTMLElement> {
	ref: MutableRefObject<T | null>;
}

/**
 * Return a mutable object ref. When this subtree mounts this ref will be registered.
 */
export const useRegisterNudgeTarget = <T extends HTMLElement>(
	id: string,
	shouldRegister = true,
): NudgeTarget<T> => {
	const controller = useNudgeController();
	const ref = useRef<T>(null);

	useLayoutEffect(() => {
		if (shouldRegister) {
			const unregister = controller.registerNudgeTarget(id, ref);

			return () => {
				unregister();
			};
		}
	}, [controller, id, shouldRegister]);

	return { ref };
};
